<template>
  <header class="knives__header">
    <div class="container">
      <div class="knives__header-top">
        <div class="knives__header-top__left">
          <button
            class="burger"
            @click="openMenu"
            :class="{ 'is-active': isOpenMenu }"
          >
            <span class="burger-line burger-top"></span>
            <span class="burger-line burger-middle"></span>
            <span class="burger-line burger-bottom"></span>
          </button>
          <locale-router-link :to="`/`" class="knives__header-top__logo">
            <img
              class="logo-img"
              src="@/assets/img/icons/logo.svg"
              alt="Логотип"
              width="221"
              height="40"
            />
          </locale-router-link>
        </div>
        <div class="knives__header-buttons">
          <button
            class="knives__header-btn"
            @click="sctollToElement('conditions')"
            :class="{ active: anchor === 'conditions' }"
          >
            {{ $t("knives.conditions") }}
          </button>

          <button
            class="knives__header-btn"
            @click="sctollToElement('partners')"
            :class="{ active: anchor === 'partners' }"
          >
            {{ $t("knives.knivesPartners") }}
          </button>
          <button
            class="knives__header-btn"
            @click="sctollToElement('prizes')"
            :class="{ active: anchor === 'prizes' }"
          >
            {{ $t("knives.prizes") }}
          </button>
          <button
            class="knives__header-btn"
            @click="sctollToElement('rules')"
            :class="{ active: anchor === 'rules' }"
          >
            {{ $t("knives.rules") }}
          </button>
        </div>
        <div class="knives__header-royal">
          <img src="@/assets/img/knives/roalvkb.png" alt="" />
        </div>
        <knives-lang-select />
      </div>
      <div class="knives__header-bottom">
        <router-link tag="button" to="/" class="knives__header-back">
          <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 13L1 7L7 1"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>
            {{ $t("knives.back") }}
          </span>
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store";
import { actionTypes as actionTypesKnives } from "@/store/modules/knives";

export default {
  name: "KnivesHeader",
  components: {
    KnivesLangSelect: () => import("@/components/knives/KnivesLangSelect.vue"),
  },
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["isOpenMenu"]),
    ...mapState("knivesModule", ["anchor"]),
  },
  watch: {
    isOpenMenu: {
      handler(newVal) {
        if (newVal) {
          document.querySelector("html").style.overflow = "hidden";
        } else {
          document.querySelector("html").style.overflow = "initial";
        }
      },
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      openMenu: actionTypes.changeIsOpenMenu,
    }),
    ...mapActions("knivesModule", {
      getAnchor: actionTypesKnives.getAnchor,
    }),
    sctollToElement(element) {
      switch (element) {
        case "conditions":
          document.getElementById("knives-conditions").scrollIntoView();
          break;
        case "partners":
          document.getElementById("knives-partners").scrollIntoView();
          break;
        case "prizes":
          document.getElementById("knives-prizes").scrollIntoView();
          break;
        case "rules":
          document.getElementById("knives-rules").scrollIntoView();
          break;
      }
    },
  },
};
</script>
